<template>
  <div class="home">
    <section class="bg-light" id="paymentsection" >
        <div class="container">
            <div class="row section-title justify-content-center text-center">
              <div class="col-md-9 col-lg-8 col-xl-7">
              <h3 class="display-4">Mullan Online Payment</h3>
              <div class="lead">Please select who you would like to make the payment to</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-4 mb-3 mb-md-4 mb-lg-0">
              <div class="card h-100 hover-box-shadow">
                <div class="d-block  rounded-top position-relative">
                <router-link to="/technology"><img class="card-img-top hover-fade-out" src="@/assets/img/technology.jpg" alt="Technology" ></router-link>
                
                </div>
                
              </div>
              </div>
              <div class="col-md-6 col-lg-4 mb-3 mb-md-4 mb-lg-0">
              <div class="card h-100 hover-box-shadow">
                <div class="d-block  rounded-top position-relative">
                <router-link to="/training"><img class="card-img-top hover-fade-out" src="@/assets/img/training.jpg" alt="Training" ></router-link>
                
                </div>
              
              </div>
              </div>
              <div class="col-md-6 col-lg-4 mb-3 mb-md-4 mb-lg-0">
              <div class="card h-100 hover-box-shadow">
                <div class="d-block  rounded-top position-relative">
                <router-link to="/property"><img class="card-img-top hover-fade-out" src="@/assets/img/property.jpg" alt="Property" ></router-link>
                
                </div>
                
              </div>
              </div>
            </div>
        </div>
		</section>
		
		
		
		
	
    
   
		
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    //HelloWorld
  }
}
</script>
